/*




Array.prototype.clean = function() {
	return this.filter(x => x)
}


*/

export class ArrayUtils {
	static sortBy = function (arr, p) {
		arr.sort(function (a, b) {
			if (a[p] > b[p]) return 1;
			if (a[p] < b[p]) return -1;
			return 0;
		});
		return arr;
	};

	static findOne(arr, key, value) {
		if (!arr) return null;
		for (let i = 0; i < arr.length; i++) {
			if (`${window.path(arr[i], key)}` === `${value}`) return arr[i];
		}
	}

	static last(arr) {
		return arr ? arr[arr.length - 1] : null;
	}

	static insert(arr, obj) {
		if (!arr.includes(obj)) arr.push(obj);
		return arr;
	}

	static remove(arr, obj) {
		let index = arr.indexOf(obj);
		if (index > -1) arr.splice(index, 1);
	}

	static toggle(arr, obj) {
		arr.includes(obj) ? this.remove(arr, obj) : arr.push(obj);
	}

	static joinArray(arr) {
		return Array.prototype.concat.apply([], arr);
	}

	static chunk(arr, n) {
		return Array.from(Array(Math.ceil(arr.length / n)), (_, i) =>
			arr.slice(i * n, i * n + n)
		);
	}

	static clear(arr) {
		arr.length = 0;
		return arr;
	}

	static unique(arr) {
		return Array.from(new Set(arr));
	}
	static append = function (arr, ar) {
		let args = ar.slice(0);
		args.unshift(0);
		args.unshift(arr.length);
		Array.prototype.splice.apply(arr, args);
		return arr;
	};

	static sum(arr) {
		return arr.reduce((a, b) => a + b, 0);
	}

	static flat(arr, path, defaultValue) {
		const result = arr.map(item => {
			let newItem = item;
			path.split('.').forEach(p => {
				if (!newItem) return;
				newItem = newItem[p];
			});
			return newItem || defaultValue;
		});
		return result;
	}
}

window.ArrayUtils = ArrayUtils;
