function $$applyAsync(target) {
	const AsyncFunction = (async () => {}).constructor;

	let p = target.prototype;
	let ks = Object.getOwnPropertyNames(p);
	ks.forEach(k => {
		if (p[k] instanceof AsyncFunction) {
			let oldPk = p[k];

			p[k] = async function (...args) {
				const { $rootScope } = inject('$rootScope');
				try {
					let r = await oldPk.apply(this, args);
					if ($rootScope) $rootScope.$applyAsync();
					return r;
				} catch (e) {
					if ($rootScope) $rootScope.$applyAsync();
					throw e;
				}
			};
		}
	});
}

function verifyRequiredKeys(type, keys, value) {
	for (let key of keys) {
		if (!value[key]) {
			throw new Error(
				`The '${key}' key is not defined in a decorator of the type ${type}`
			);
		}
	}
}

window.Inject = function Inject(value) {
	return function decorator(target) {
		target.$inject = value;
	};
};

window.Html = function Html(value) {
	return function decorator(target) {
		target.template = value;
	};
};

export const Component = function (value) {
	verifyRequiredKeys(Component.name, ['selector', 'module'], value);
	const injected = ['$scope', '$element'].concat(value.inject || []);

	return function decorator(target) {
		$$applyAsync(target);
		const name = window.snakeToCamel(value.selector);
		const ComponentProxy = class extends target {
			constructor(...args) {
				let oldArgs = [].concat(args);
				args.shift();
				args.shift();
				super(...args);

				injected.forEach((i, k) => (this[i] = oldArgs[k]));
			}

			static get $inject() {
				return injected;
			}
		};

		angular.module(value.module).component(
			name,
			Object.assign({}, value, {
				controllerAs: 'view',
				controller: ComponentProxy,
			})
		);
	};
};

window.Component = Component;

export const Service = function (value) {
	verifyRequiredKeys(Service.name, ['name', 'module'], value);

	return function decorator(target) {
		$$applyAsync(target);
		value.inject = value.inject || [];
		angular.module(value.module).service(
			value.name,
			value.inject.concat([
				function (...injected) {
					const inst = new target(...injected);
					for (let i = 0; i < value.inject.length; i++) {
						inst[value.inject[i]] = injected[i];
					}
					return inst;
				},
			])
		);
	};
};

window.Service = Service;

export const Factory = function (value) {
	verifyRequiredKeys(Factory.name, ['name', 'module'], value);

	return function decorator(target) {
		$$applyAsync(target);
		value.inject = value.inject || [];
		target.$inject = value.inject;
		angular.module(value.module).factory(
			value.name,
			value.inject.concat([
				function (...injected) {
					const inst = new target(...injected);
					for (let i = 0; i < value.inject.length; i++) {
						inst[value.inject[i]] = injected[i];
					}
					if (inst.$onInit) inst.$onInit();
					return inst;
				},
			])
		);
	};
};

window.Factory = Factory;

export const Filter = function (value) {
	verifyRequiredKeys(Filter.name, ['name', 'module'], value);

	return function decorator(target) {
		value.inject = value.inject || [];

		$$applyAsync(target),
			angular.module(value.module).filter(
				value.name,
				value.inject.concat([
					function (...injected) {
						const inst = new target();
						for (let i = 0; i < value.inject.length; i++) {
							inst[value.inject[i]] = injected[i];
						}
						const fn = (...args) => inst.filter(...args);
						return fn;
					},
				])
			);
	};
};

window.Filter = Filter;

export const Dialog = function (value) {
	verifyRequiredKeys(Dialog.name, ['name', 'module'], value);

	return function decorator(target) {
		$$applyAsync(target);

		class DialogProxy extends target {
			constructor() {
				super();
				value.inject = value.inject || [];
				Object.assign(
					this,
					inject(
						'$mdDialog',
						'popup',
						'gettextCatalog',
						...value.inject
					)
				);
			}

			show(ev, ...params) {
				let controllerInstance = this;

				return this.$mdDialog
					.show(
						Object.assign(
							{
								template: this.template,
								parent: angular.element(document.body),
								targetEvent: ev,
								clickOutsideToClose: true,
								bindToController: true,
								multiple: true,
								onRemoving: () => {
									if (controllerInstance.$onClose)
										controllerInstance.$onClose();
								},
							},
							value,
							{
								controllerAs: 'view',
								controller: [
									'$scope',
									function ($scope) {
										this.$scope = $scope;
										Object.assign(this, controllerInstance);
										Object.setPrototypeOf(
											this,
											controllerInstance
										);
										controllerInstance = this;

										if (this.$onShow)
											this.$onShow(...params);
									},
								],
							}
						)
					)
					.catch(() => {});
			}

			confirm(data) {
				this.$mdDialog.hide(data);
			}

			cancel() {
				this.$mdDialog.cancel();
			}

			close(result) {
				return this.$mdDialog.hide(result);
			}

			async confirmToClose() {
				if (
					await this.popup.confirm(
						this.gettextCatalog.getString('Are you sure?')
					)
				) {
					this.cancel();
				}
			}
		}

		angular.module(value.module).factory(value.name, DialogProxy);
	};
};

window.Dialog = Dialog;

export const FormDialog = function (value) {
	verifyRequiredKeys(FormDialog.name, ['name', 'module'], value);

	return function decorator(target) {
		$$applyAsync(target);

		const template = `
			<md-dialog md-theme="{{$root.theme}}" ng-class="{fullscreen: view.fullscreen}" class="${camelToSnake(
				value.name
			)}">
				<md-toolbar layout="row" layout-align="start center" ng-if="view.icon || view.title">
					<div class="md-toolbar-tools" flex>
						<ion-icon ng-if="view.icon" name="{{view.icon}}"></ion-icon>
						<h3 translate>{{view.title}}</h3>
					</div>
				</md-toolbar>

				<md-dialog-content layout="column" flex="grow">
					<form name="view.form" layout="column" flex="grow">${value.template}</form>
				</md-dialog-content>

				<md-dialog-actions layout="row" layout-align="end center">
					<md-button ng-click="view.confirmToClose()" aria-label="close">
						<ion-icon name="close-outline"></ion-icon>
						<span ng-if="!view.hideSave" translate>Cancel</span>
						<span ng-if="view.hideSave" translate>Close</span>
					</md-button>

					<md-button 
						ng-disabled="view.form.$pristine || view.form.$invalid" 
						ng-click="view.save()" 
						class="md-primary md-raised"
						ng-if="!view.hideSave"
						aria-label="confirm"
					>
						<ion-icon name="checkmark-outline"></ion-icon>
						<span ng-if="!view.confirmLabel" translate>Save</span>
						<span ng-if="view.confirmLabel">{{view.confirmLabel}}</span>
					</md-button>
				</md-dialog-actions>
			</md-dialog>`;

		class FormDialogProxy extends target {
			constructor() {
				super();
				value.inject = value.inject || [];
				Object.assign(
					this,
					inject(
						'$mdDialog',
						'popup',
						'gettextCatalog',
						...value.inject
					)
				);
			}

			show(ev, ...params) {
				let controllerInstance = this;
				const controller = function ($scope) {
					this.$scope = $scope;

					Object.assign(this, value, controllerInstance);

					Object.setPrototypeOf(this, controllerInstance);
					controllerInstance = this;

					if (this.$onShow) {
						this.$onShow(...params);
					}
				};

				const config = Object.assign(
					{
						parent: angular.element(document.body),
						targetEvent: ev,
						multiple: true,
						clickOutsideToClose: false,
						escapeToClose: false,
						bindToController: true,
						onComplete: () => {
							if (controllerInstance.$onComplete)
								controllerInstance.$onComplete();
						},
						onRemoving: () => {
							if (controllerInstance.$onClose)
								controllerInstance.$onClose();
						},
					},
					value,
					{ template },
					{
						controllerAs: 'view',
						controller: ['$scope', controller],
					}
				);
				return this.$mdDialog.show(config).catch(() => {});
			}

			confirm(data) {
				this.$mdDialog.hide(data);
			}

			save() {
				super.save();
				this.$mdDialog.hide(this.value);
			}

			cancel() {
				try {
					this.$mdDialog.cancel();
				} catch (e) {}
			}

			close(result) {
				return this.$mdDialog.hide(result);
			}

			async confirmToClose() {
				if (!value.ignoreConfirmToClose && this.form.$dirty) {
					if (
						await this.popup.confirm(
							this.gettextCatalog.getString('Discard?'),
							this.gettextCatalog.getString(
								'Are you sure you want to discard all changes?'
							),
							this.gettextCatalog.getString('Continue editing'),
							this.gettextCatalog.getString('Discard')
						)
					) {
						this.cancel();
					}
				} else {
					this.cancel();
				}
			}
		}

		angular.module(value.module).factory(value.name, FormDialogProxy);
	};
};

window.FormDialog = FormDialog;

export const View = function (value) {
	if (value && !value.$resolve) {
		verifyRequiredKeys(View.name, ['name', 'route'], value);
	}

	return function decorator(target) {
		$$applyAsync(target);
		window.$$configInjector(function ($stateProvider) {
			try {
				const { $templateCache } = inject('$templateCache');

				const template =
					value.template ||
					target.template ||
					$templateCache.get(
						value.templateUrl || `${value.name}.html`
					);

				const resolveKeys = Object.keys(window.Resolve);

				class ExtendedViewClass extends target {
					static get name() {
						return value.name;
					}

					constructor($scope, $rootScope, ...resolved) {
						super(...[$scope, $rootScope].concat(resolved));

						if (value.inject || target.$inject) {
							Object.assign(
								this,
								window.inject(
									...(value.inject || []).concat(
										target.$inject || []
									)
								)
							);
						}

						this.$scope = $scope;

						if (typeof this.watches === 'function') {
							if (window.$APP_CONFIG?.debug) {
								console.warn(
									'WARNING, this controller uses "watches", avoid using watches and scopes as much as you can',
									target
								);
							}
							this.watches((watchKey, watchFn) => {
								$scope.$watch(
									watchKey,
									(a, b, c, d) => {
										if (View.verbose) console.log(a);
										watchFn.apply(this, [a, b, c, d]);
									},
									true
								);
							});
						}

						$scope.$on('$stateChangeSuccess', (event, toState) => {
							if (toState.name === value.name) {
								Object.keys(this.$resolve).forEach(v => {
									if (v.substring(0, 1) !== '$') {
										this[v] = this.$resolve[v];
									}
								});

								this.trigger(this.afterEnter);
							}
						});

						$scope.$on('$destroy', () =>
							this.destroy ? this.destroy() : 'noop'
						);
					}

					go(state, params) {
						const { $state } = inject('$state');
						$state.go(state, params);
					}

					trigger(fn) {
						if (fn) window.injector().invoke(fn, this);
					}

					broadcast(event) {
						if (this.scope) this.scope.$broadcast(event);
					}

					get name() {
						return value.name;
					}

					static get $inject() {
						return ['$scope', '$rootScope'].concat(resolveKeys);
					}

					$notifyApply() {
						this.$scope.$apply();
					}
				}

				$stateProvider.state(
					value.name,
					Object.assign({}, value, {
						url: value.route,
						template: template,
						controller: ExtendedViewClass,
						controllerAs: 'view',
						bindToController: true,
						resolve: Object.assign(
							{},
							window.Resolve,
							value.resolve || {}
						),
					})
				);
			} catch (e) {
				console.log(e);
			}
		});
	};
};
window.View = View;
