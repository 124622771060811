window.EventEmitter = class {
	constructor() {
		this.listeners = {};
	}

	emit(key, ...params) {
		if (this.listeners[key] && this.listeners[key].length) {
			this.listeners[key].forEach(fn => {
				fn(this, ...params);
			});
		}
	}

	on(key, fn) {
		this.listeners[key] = this.listeners[key] || [];
		this.listeners[key].push(fn);
		return () => {
			ArrayUtils.remove(this.listeners[key], fn);
			if (!this.listeners[key].length) delete this.listeners[key];
		};
	}

	// eslint-disable-next-line no-unused-vars
	$emit(key, ...params) {
		// eslint-disable-next-line prefer-rest-params
		this.emit(...arguments);
	}

	$on(key, fn) {
		return this.on(key, fn);
	}
};
