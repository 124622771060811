Number.prototype.round = function (decimal) {
	return Math.round(this * Math.pow(10, decimal)) / Math.pow(10, decimal);
};

Number.prototype.pad = function (width) {
	let n = `${this}`;
	return n.length >= width
		? n
		: new Array(width - n.length + 1).join('0') + n;
};

Number.prototype.toRad = function () {
	return (this * Math.PI) / 180;
};

Number.prototype.toDeg = function () {
	return (this * 180) / Math.PI;
};

function decimalAdjust(type, value, exp) {
	if (typeof exp === 'undefined' || +exp === 0) return Math[type](value);

	value = +value;
	exp = +exp;
	if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN;

	value = value.toString().split('e');
	value = Math[type](+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);
	value = value.toString().split('e');
	return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
}

if (!Math.round10) {
	Math.round10 = function (value, exp) {
		decimalAdjust('round', value, exp);
	};
}

if (!Math.floor10) {
	Math.floor10 = function (value, exp) {
		decimalAdjust('floor', value, exp);
	};
}

if (!Math.ceil10) {
	Math.ceil10 = function (value, exp) {
		return decimalAdjust('ceil', value, exp);
	};
}
