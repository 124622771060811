//Moment was imported using script, before it was added to package.json.
//It's easier to maintain it in package.json but for legacy code that still use moment without importing it, we need to define window.moment
//with the lib we have in our package.json
window.moment = require('moment-timezone');

/* css */
import './modules/uz-web.scss';
import './v2/styles.scss';

import {} from './general/decorators';
import {} from './general/angular';
import {} from './general/utils';
import {} from './general/event';
import {} from './general/string';
import {} from './general/array';
import {} from './general/number';
import {} from './general/html';
import {} from './general/promise';

import(
	/* webpackMode: "lazy-once" */
	/* webpackChunkName: "uz-core" */
	'./core/uz-core'
);
import(
	/* webpackMode: "lazy-once" */
	/* webpackChunkName: "uz-web" */
	'./modules/_app/_main/app'
);
