Promise.sequence = function (array, strong) {
	const results = [];

	return new Promise(function (resolve, reject) {
		function next(r) {
			results.push(r);
			return array.length === 0 ? resolve(results) : doOne();
		}

		function doOne() {
			let p = array.shift();
			p()
				.then(r => next(r))
				.catch(err => (strong ? next(err) : reject(err)));
		}

		array.length > 0 ? doOne() : resolve([]);
	});
};
