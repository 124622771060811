angular.lowercase = s => s?.toLowerCase()

window.html = function (name, template) {
	if (window.$templateCache) {
		window.$templateCache.put(name, template)
	} else {
		angular.module('uz').run(function ($templateCache) {
			$templateCache.put(name, template)
			window.$templateCache = $templateCache
		})
	}
}

window.injector = function () {
	return angular.element(document.body).injector() || angular.injector()
}
export const inject = function (...arInject) {
	let r = {}
	let inj = angular.element(document.body).injector() || angular.injector()
	arInject.forEach(k => (r[k] = inj.get(k)))
	return r
}

window.inject = inject

window.$$configInjector = function (fn) {
	if (window.$stateProvider) {
		fn(window.$stateProvider)
	} else {
		window.$$configInjector.list.push(fn)
	}
}
window.$$configInjector.list = []

angular.module('uz').config(function ($stateProvider) {
	window.$stateProvider = $stateProvider
	window.$$configInjector.list.forEach(function (fn) {
		fn($stateProvider)
	})
})

angular.toBoolean = function (value) {
	if (value && value.length !== 0) {
		let v = angular.lowercase(`${value}`)
		value = !(
			v == 'f' ||
			v == '0' ||
			v == 'false' ||
			v == 'no' ||
			v == 'n' ||
			v == '[]'
		)
	} else {
		value = false
	}
	return value
}

angular.addScript = function (filepath, callback, isAsync) {
	return new Promise(resolve => {
		let fileref = document.createElement('script')

		fileref.onload = function () {
			if (callback) callback()
			resolve()
		}

		fileref.setAttribute('type', 'text/javascript')
		fileref.setAttribute('src', filepath)
		if (isAsync) fileref.setAttribute('async', 1)

		if (typeof fileref != 'undefined') {
			angular.element(document).ready(function () {
				document.getElementsByTagName('head')[0].appendChild(fileref)
			})
		}
	})
}
angular.addStyle = function (filepath, callback) {
	if (filepath) {
		let fileref = document.createElement('link')

		fileref.onload = callback

		fileref.setAttribute('type', 'text/css')
		fileref.setAttribute('href', filepath)
		fileref.setAttribute('rel', 'stylesheet')

		document.getElementsByTagName('head')[0].appendChild(fileref)
	}
}
