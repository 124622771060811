Element.ancestor = function (node, match) {
	if (!node) return null;
	if (!node.nodeType || typeof match != 'string') return node;
	if ((match = match.split('.')).length === 1) {
		match.push(null);
	} else if (!match[0]) {
		match[0] = null;
	}
	do {
		if (
			(!match[0] ||
				match[0].toLowerCase() == node.nodeName.toLowerCase()) &&
			(!match[1] ||
				new RegExp(`( |^)(${match[1]})( |$)`).test(node.className))
		) {
			break;
		}
	} while ((node = node.parentNode));

	return node;
};
