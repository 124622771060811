String.prototype.startWith = function (f) {
	return this.substring(0, f.length) === f;
};

String.prototype.capitalizeFirstLetter = function () {
	return this.substr(0, 1).toUpperCase() + this.slice(1).toLowerCase();
};

String.prototype.format = function (...args) {
	return this.replace(/\{(\d+)\}/g, (e, i) => args[i]);
};

String.prototype.hashCode = function () {
	let hash = 0;
	let i;
	let chr;
	let len;
	if (this.length == 0) return hash;
	for (i = 0, len = this.length; i < len; i++) {
		chr = this.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0;
	}
	return hash;
};
