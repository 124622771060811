import { generateSnakeFromName } from './generate-snake-from-name';

Object.subset = function (obj, ...props) {
	let r = {};
	props.forEach(p => (r[p] = obj[p]));
	return r;
};

window.path = function (o, p) {
	if (p == '.' || p == null) return o;

	let paths = p.split('.');
	let cur = o;

	for (let j = 0; j < paths.length; j++) {
		cur = cur[paths[j]];
		if (cur == null) return null;
	}

	return cur;
};

window.functionName = function (fun) {
	let ret = fun.toString();
	ret = ret.substr('function '.length);
	ret = ret.substr(0, ret.indexOf('('));
	return ret;
};

window.camelToSnake = function (str) {
	return generateSnakeFromName(str);
};

window.snakeToCamel = function (str, firstUp) {
	let r = str.replace(/\W+(.)/g, (x, chr) => chr.toUpperCase());
	if (!firstUp) return r;

	return r.substring(0, 1).toUpperCase() + r.substring(1);
};
(function () {
	let throttle = function (type, name, obj) {
		obj = obj || window;
		let running = false;
		let func = function () {
			if (running) {
				return;
			}
			running = true;
			requestAnimationFrame(function () {
				obj.dispatchEvent(new CustomEvent(name));
				running = false;
			});
		};
		obj.addEventListener(type, func);
	};

	/* init - you can init any event */
	throttle('resize', 'optimizedResize');
})();
