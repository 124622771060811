import { remove as removeDiacritics } from 'diacritics';

export function generateSnakeFromName(name: string): string {
	return typeof name === 'string'
		? removeDiacritics(name)
				.trim()
				.replace(/\W+/g, '-')
				.replace(/([a-z\d])([A-Z])/g, '$1-$2')
				.replace(/_/g, '-')
				.toLowerCase()
		: name;
}
